import {
  CREATE_DAILY_NOTES,
  CREATE_DIARY_NOTE,
  CREATE_HOMEWORK,
  DELETE_HOMEWORK,
  GET_DAILY_NOTES,
  GET_DIARY_NOTE,
  GET_HOMEWORK,
  UPDATE_DAILY_NOTES,
  UPDATE_DIARY_NOTE,
} from "../Constants/constants";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "./Api";

const createHomework = (payLoad, params) => {
  return PostAPIRequest({ url: CREATE_HOMEWORK, data: payLoad, params });
};
const getHomework = (staffId, params) => {
  return GetAPIRequest({
    url: GET_HOMEWORK.replace("{staffId}", staffId),
    params,
  });
};
const getDailyNotes = (classId, params) => {
  return GetAPIRequest({
    url: GET_DAILY_NOTES.replace("{classId}", classId), params
  });
};
const createDailyNotes = (payload, params) => {
  return PostAPIRequest({ url: CREATE_DAILY_NOTES, data: payload, params });
};
const updateDailyNotes = (dailyNotesId, payload, params) => {
  return PostAPIRequest({ url: UPDATE_DAILY_NOTES.replace("{dailyNotesId}", dailyNotesId), data: payload, params });
};
const getDiaryNotes = (classId) => {
  return GetAPIRequest({
    url: GET_DIARY_NOTE.replace("{classId}", classId),
  });
};
const createDiaryNote = (payload) => {
  return PostAPIRequest({ url: CREATE_DIARY_NOTE, data: payload });
};
const updateDiaryNote = (diaryNoteId, payload) => {
  return PostAPIRequest({ url: UPDATE_DIARY_NOTE.replace("{diaryNoteId}", diaryNoteId), data: payload });
};
const deleteHomework = (staffId, params) => {
  return DeleteAPIRequest({
    url: DELETE_HOMEWORK.replace("{staffId}", staffId),
    params,
  });
};
export const HomeworkService = { 
  createHomework, 
  getHomework, 
  deleteHomework,
  updateDailyNotes,
  getDailyNotes,
  createDailyNotes,
  getDiaryNotes,
  createDiaryNote,
  updateDiaryNote,
};
