import { Space, Table } from "antd"
import DailyNotesModal from "./DailyNotesModal"
import { EditOutlined, FileOutlined, FilePdfFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { HomeworkService } from "../../Services/HomeworkService";
import FileView from "../../Utils/FileView";
import DiaryNoteModal from "./DiaryNoteModal";

const DiaryNote = ({
    diaryNoteModal,
    setDiaryNoteModal,
    selectedSection,
}) => {
    const [diaryNote, setDiaryNote] = useState(null);
    const [record, setRecord] = useState(null);
    const [view, setView] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        getDiaryNotesData();
    }, [selectedSection]);

    const getDiaryNotesData = () => {
        HomeworkService.getDiaryNotes(selectedSection?.classId)
            .then(response => {
                setDiaryNote(response?.data?.reverse());
            })
            .catch(error => { });
    }

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            align: "center",
        },
        {
            title: "Notes",
              dataIndex: "diaryNoteUrl",
            //   key: "diaryNote",
            render: (diaryNoteUrl) => diaryNoteUrl ? (<FilePdfFilled onClick={() => handleFileView(diaryNoteUrl)} />) : ("-"),
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_, record) => (
                <Space>
                    <EditOutlined onClick={() => handleEditDiaryNote(record)} />
                </Space>
            ),
        },
    ];

    const handleFileView = (url) => {
        setFileUrl(url);
        setView(true);
    };

    const handleEditDiaryNote = (record) => {
        setDiaryNoteModal(true);
        setRecord(record);
    }
    return (
        <>
            <Table
                dataSource={diaryNote}
                columns={columns}
                pagination={{ pageSize: 20 }}
            />
            {diaryNoteModal && (
                <DiaryNoteModal
                    diaryNoteModal={diaryNoteModal}
                    setDiaryNoteModal={setDiaryNoteModal}
                    record={record}
                    setRecord={setRecord}
                    selectedSection={selectedSection}
                    getDiaryNotesData={getDiaryNotesData}
                />
            )}
            {view && (
                <FileView
                    view={view}
                    setView={setView}
                    fileData={fileUrl}
                    heading="Diary Note"
                />
            )}
        </>
    )
}

export default DiaryNote