import { Button, Col, Form, Modal, Row, Upload } from "antd"
import FormButton from "../Utils/FormButtons";
import { dynamicErrorMsg, errorNotification, homeworkTabs, successNotification } from "../../Constants/utilitis";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import Cookies from "js-cookie";
import { HomeworkService } from "../../Services/HomeworkService";

const DailyNotesModal = ({
    diaryNoteModal,
    setDiaryNoteModal,
    record,
    setRecord,
    selectedSection,
    getDiaryNotesData,
}) => {
    const [diaryNoteForm] = Form.useForm();
    const [file, setFile] = useState([]);

    const handleCancel = () => {
        setDiaryNoteModal(false);
        diaryNoteForm.resetFields();
        setRecord(null);
    }
    const onFinish = () => {
        const formData = new FormData();
        formData.append("classId", selectedSection?.classId);
        file.map((file, i) => {
            formData.append(`diaryNoteFile`, file);
        });
        formData.append("staffId", Cookies.get("userId"));

        if (record?.id) {
            HomeworkService.updateDiaryNote(record?.id, formData)
                .then(response => {
                    successNotification(response?.data);
                    getDiaryNotesData();
                    handleCancel();
                })
                .catch(error => errorNotification(dynamicErrorMsg(error, "Update", "Diary Note")));
        }
        else {
            HomeworkService.createDiaryNote(formData)
                .then(response => {
                    successNotification(response?.data);
                    getDiaryNotesData();
                    handleCancel();
                })
                .catch(error => errorNotification(dynamicErrorMsg(error, "Create", "Diary Note")));
        }
    }

    const handleUpload = ({ fileList }) => {
        const modifiedFiles = [];
        fileList?.forEach(file => modifiedFiles.push(file.originFileObj));
        setFile(modifiedFiles);
    };

    return (
        <Modal
            title={`${record ? "Edit" : "Create"} ${homeworkTabs[2]}`}
            open={diaryNoteModal}
            onCancel={handleCancel}
            footer={null}
        >
            <Form form={diaryNoteForm} onFinish={onFinish} onFinishFailed={() => errorNotification("Please, Upload Notes")}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                        <Form.Item name="diaryNoteFile"
                            rules={[
                                {
                                    required: true,
                                    message: "",
                                },
                            ]}
                        >
                            <Upload
                                onChange={handleUpload}
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                showUploadList={true}
                                beforeUpload={() => false}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    className="blue-button"
                                >
                                    Upload Diary Note
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <FormButton
                    saveText={"Save"}
                    cancelText={"Cancel"}
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default DailyNotesModal