export const BASE_LOGIN_URL = `https://api.edpedia.co/v1.0/ulp`;
export const BASE_URL = `https://api.edpedia.co/v1.0/instAdmin`;
export const STAFF_BASE_URL = `https://api.edpedia.co/v1.0/staff`;
export const TRANSPORT_BASE_URL = `https://api.edpedia.co/v1.0/staff`;
export const EDIT_SYLLABUS_BASE_URL = `http://15.207.40.153:8080/v1.0`;
export const INSTITUTE_BASE_URL = `https://api.edpedia.co/v1.0/superAdmin`;
export const LIBRARY_BASE_URL = `https://api.edpedia.co/v1.0/library`;
export const EDPEDIA_STORE_BASE_URL = `https://api.edpedia.co/v1.0/store`;
export const ATTENDANCE_BASE_URL = "https://api.edpedia.co/v1.0/attendance";
export const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = API_BASE_URL;

// export const staffId = 3;
/*get exam details*/
export const GET_STAFF_EXAM = STAFF_BASE_URL + `api/staff/`;
export const GET_STAFF_EXAM_ENDPOINT = `/exam`;
export const POST_QUESTION_PAPER =
  STAFF_BASE_URL + `/api/exam/uploadQuestionPaper`;
//staff urls
export const VIEW_STAFF_LIST =
  BASE_URL + `/api/staff/list?offSet=0&pageSize=10`;
export const FIND_TEACHING_STAFF = STAFF_BASE_URL + `/api/`;
export const FIND_TEACHING_STAFF_ENDPOINT = `/find`;
export const GETTODAY_TIMETABLE = STAFF_BASE_URL + `/api/staff/`;
export const GETTODAY_TIMETABLE_END_PONT = `/getTodayTimetable`;
export const VIEW_TEACHING_SUBJECTS = STAFF_BASE_URL + `/api/staff/`;
export const VIEW_TEACHING_SUBJECTS_ENDPOINT = `/teachingSubject`;
export const EDIT_STAFF_URL = BASE_URL + `/api/staff/`;
export const EDIT_ENDPOINT = `/edit`;
export const TEACHING_SUBJECT_LIST = STAFF_BASE_URL + `/api/staff/`;
export const TEACHING_SUBJECT_LIST_ENDPOINT = `/teaching_subject`

//assignments
export const ASSIGNMENT_LIST_URL_ENDPOINTS = `/getAssignmentsByStaffId`;
export const ASSIGNMENT_LIST = STAFF_BASE_URL + `/api/assignment/`;
export const POST_ASSIGNMENT = STAFF_BASE_URL + `/api/assignment/create`;
export const EDIT_ASSIGNMENT = STAFF_BASE_URL + `/api/assignment/`;
export const EDIT_ASSIGNMENT_ENDPOINT = `/edit`;
export const FIND_ASSIGNMENT = STAFF_BASE_URL + `/api/assignment/`;

export const DELETE_INDIVIDUAL_FILE = STAFF_BASE_URL + `/api/assignment/`;
export const DELETE_INIDIVIDUAL_FILE_ENDPOINT = `/deleteFile`;

export const FIND_ASSIGNMENT_ENDPOINT = +`/find`;

export const SUBMIT_ASSIGNMET = STAFF_BASE_URL + `/api/assignment/`;
export const SUBMIT_ASSIGNMENT_ENDPOINT = `/findSubmittedStudents`;

//result
export const CREATE_RESULT = BASE_URL + `/api/result/save`;
export const GET_CLASSWISE_STUDENTS = BASE_URL + `/api/student/`;
export const GET_CLASSWISE_STUDENTS_ENDPOINT = `/getClassStudents`;
export const GET_CLASS_SUBJECTWISE_RESULTS =
  STAFF_BASE_URL + `/api/result/classSubjectWiseResult`;
export const GET_RESULT_DATA =
  STAFF_BASE_URL + `/api/result/getResultOfStaffClass`;
export const GET_RESULT_API =
  BASE_URL + `/api/result/list?sortField=resultId&sortOrder=ASC&`;
export const GET_RESULT_API_ENDPOINT = `&offSet=0&pageSize=200`;
export const SAVE_STUDENT_RESULT = STAFF_BASE_URL + `/api/result/`;
export const SAVE_STUDENT_RESULT_ENDPOINT = `/edit`;

/*ANNOUNCEMENT API*/
export const CREATE_ANNOUNCEMENT_URL = BASE_URL + `/api/announcement/create`;
export const EDIT_ANNONCE_URL = BASE_URL + `/api/announcement/edit/`;
export const ANNOUNCE_LIST =
  BASE_URL + `/api/announcement/list?offSet=0&pageSize=30`;
export const VIEW_ANNOUNCE_URL = BASE_URL + `/api/announcement/find/`;
export const STUDENT_ASSIGNMENT_SCORE =
  STAFF_BASE_URL + `/api/assignment/updateStatus`;

//GET ALL CLASSES
export const GET_ALL_CLASSES_URL = BASE_URL + `/api/class/getAll`;

//PREVIOUS_EMPLOYER  //pending
export const VIEW_PREVIOUS_EMPLOYER_LIST =
  STAFF_BASE_URL + `/api/staff/previousEmployment/list?offSet=0&pageSize=10`;

// Banking
export const VIEW_BANKING_LIST =
  STAFF_BASE_URL + `/api/staff-bank-account/list?offSet=0&pageSize=10`;
export const POST_BANKING =
  STAFF_BASE_URL + `/api/staff/staffBankAccount/create`;
export const FIND_BANK_DETAILS = BASE_URL + `/api/staffBankAccount/`;
export const FIND_BANK_DETAILS_ENDPOINT = `/find`;
export const EDIT_BANK_URL = STAFF_BASE_URL + `/api/staff/staffBankAccount/`;
export const EDIT_BANK_ENDPOINT = `/edit`;

//Storage Controller
export const POST_STORAGE_CONTROLLER = BASE_URL + `/api/storage/upload`;

/*exam api */
export const EXAM_LIST_URL = STAFF_BASE_URL + `/api/staff/`;
export const EXAM_LIST_ENDPOINT = `/exam`;

// previous employement create
export const CREATE_PREVIOUS_EMPLOYEMENT =
  STAFF_BASE_URL + `/api/staff/previousEmployment/create`;

export const FIND_PREVIOUS_EMPLOYEEMENT_DETAILS =
  STAFF_BASE_URL + `/api/staff/previousEmployment/list`;

//subjects
export const GET_ALL_SUBJECTS =
  BASE_URL + `/api/subject/getAllSubjects?offSet=0&pageSize=10`;
export const GET_STUDENT_DETAILS = BASE_URL + `/api/student/`;
export const STUDENT_ATTENDANCE_LIST = `${ATTENDANCE_BASE_URL}/api/class-attendance/students/{classId}`;

export const SAVE_ATTENDANCE_ENDPOINT = `${ATTENDANCE_BASE_URL}/api/class-attendance/{classAttendanceId}/update`;
export const GET_ALL_STUDENTS = BASE_URL + `/api/student/getAllStudents`;
export const GET_TODAYTIMETABLE_ATTENDANCE = STAFF_BASE_URL + `/api/staff/`;
export const GET_TODAYTIMETABL_ATTENDANCE_ENDPOINT = `/getTodayTimetable`;
export const GET_CLASS_TIME_TABLE = STAFF_BASE_URL + `/api/classTimeTable`;

//http://15.207.40.153:8013/v1.0/api/staff/3/getTodayTimetable
/*study materials*/
export const CREATE_STUDYMATERIAL =
  STAFF_BASE_URL + `/api/studyMaterial/create`;
export const STUDYMATERIAL_LIST =
  STAFF_BASE_URL + `/api/studyMaterial/getAllStudyMaterials`;
export const EDIT_STUDYMATERIALS = STAFF_BASE_URL + `/api/studyMaterial/`;
export const EDIT_STUDYMATERIAL_ENDPOINT = `/edit`;
export const FIND_STUDYMATERIAL = STAFF_BASE_URL + `/api/studyMaterial/`;
export const FIND_STUDYMATERIAL_ENDPOINT = `/find`;
export const DELETE_STUDYMATERIAL_FILE = STAFF_BASE_URL + `/api/studyMaterial/`;
export const DELETE_STUDYMATERIAL_ENDPOINT = `/deleteFile`;

/*human resorce*/
export const GETALL_RESIGNATION =
  BASE_URL + `/api/hrOperations/getAllResignation`;

export const GET_RESIGNATION = STAFF_BASE_URL + `/api/staffResignation/`;
export const GET_RESIGNATION_ENDPOINT = `/getAllResByStaffId`;

export const CREATE_RESIGNATION = STAFF_BASE_URL + `/api/staffResignation/`;
export const CREATE_RESIGNATION_ENDPOINT = `/dropResignation`;

export const EDIT_RESIGNATION = STAFF_BASE_URL + `/api/staffResignation/`;
export const EDIT_RESIGNATION_ENDPOINTS = `/updateResStatus`;

export const GET_ALL_REGULARAIZATIONS_REQUEST =
  ATTENDANCE_BASE_URL + `/api/hrOperations/getAllRegularization`;
export const GENERATE_PAYSLIP = STAFF_BASE_URL + `/api/staff/`;
export const GENERATE_PAYSLIP_ENPOINT = `/findPaySlip`;
export const GETALL_STAFF_UNLOCK_REQUEST =
  BASE_URL + `/api/hrOperations/getAllUnlockRequest`;
export const CREATE_UNLOCK_REQUEST =
  ATTENDANCE_BASE_URL + `/api/staffAttendance/`;
export const CREATE_UNLOCK_REQUEST_ENDPOINT = `/regUnlockRequest`;
export const EDIT_REGULARIZATION =
  ATTENDANCE_BASE_URL + `/api/staffAttendance/`;
export const EDIT_REGULARIZATION_ENDPOINTS = `/updateRegularizationInfo`;
export const CREATE_REGULARIZATION_REQUEST =
  ATTENDANCE_BASE_URL + `/api/staffAttendance/requestForRegularization`;
export const GET_STAFF_REGULARIZATION =
  ATTENDANCE_BASE_URL + `/api/staffAttendance/`;
export const GET_STAFF_REGULARIZATION_ENDPOINT = `/getRegularization`;
export const GET_STAFF_UNLOCK_REQUEST =
  ATTENDANCE_BASE_URL + `/api/staffAttendance/`;
export const GET_STAFF_UNLOCK_REQUEST_ENDPOINT = `/getUnlockReq`;
//Holidays
export const GET_HOLIDAYS =
  BASE_URL +
  `/api/holiday/list?offSet=0&sortField=holidayId&sortOrder=ASC&pageSize=10`;
export const CREATE_HOLIDAYS = BASE_URL + `/api/holiday/create`;

//leaves controller
export const LEAVES_LIST = BASE_URL + `/api/leave/list?offSet=0&pageSize=10`;
export const FIND_LEAVE = BASE_URL + `/api/leave/`;
export const FIND_LEAVE_ENDPOINT = `/find`;
export const STAFF_LEAVE_USAGE = BASE_URL + `/api/staff`;
export const STAFF_LEAVE_USAGE_ENDPOINT = `getLeaveUsage`;
export const FIND_STAFF_APPLIED_LEAVES = STAFF_BASE_URL + `/api/leave/`;
export const FIND_STAFF_APPLIED_LEAVES_ENDPOINT = `/getLeavesByStaffId`;
export const CREATE_LEAVE = STAFF_BASE_URL + `/api/leave/create`;
// /api/leave/{leaveId}/staffLeaveEdit
export const APPLIED_LEAVE_EDIT = STAFF_BASE_URL + `/api/leave/`;
export const APPLIED_LEAVE_EDIT_ENDPOINT = `/staffLeaveEdit`;
//student leave approve

export const GET_STUDENT_LEAVE = STAFF_BASE_URL + `/api/staff/`;
export const GET_STUDENT_LEAVE_ENDPOINT = `/leaveRequests`;
export const UPDATE_STUDENT_LEAVEREQUEST_STATUS =
  STAFF_BASE_URL + `/api/staff/`;
export const UPDATE_STUDENT_LEAVEREQUEST_STATUS_ENDPOINT = `/approve`;
//leave calendar controller
export const LEAVE_CALENDAR_LIST = BASE_URL + `/api/leave/leaveCalenderList`;
export const CREATE_LEAVE_CALENDAR = BASE_URL + `/api/leaveCalender/create`;
export const EDIT_LEAVE_CALENDAR = BASE_URL + `/api/staff/`;
export const EDIT_LEAVE_CALENDAR_ENDPOINT = `/getLeaveUsage`;

//leave approval controller
export const LEAVE_APPROVAL_LIST =
  BASE_URL + `/api/leaveApproval/list?offSet=0&pageSize=10`;
export const EDIT_LEAVE_APPROVAL_LIST = BASE_URL + `/api/leaveApproval/`;
export const EDIT_LEAVE_APPROVAL_ENDPOINT = `/edit`;

//exam Syllabus
export const GET_ALL_LIST = STAFF_BASE_URL + `/api/syllabus/getSyllabusOfStaff`;
export const EDIT_SYLLABUS = STAFF_BASE_URL + `/api/syllabus/`;
export const EDIT_SYLLABUS_ENDPOINT = `/updateTopic`;

// latest activity
export const LATEST_ACTIVITY = STAFF_BASE_URL + `/api/activity/latestActivity`;

// staff attendance
export const STAFF_CHECKIN = ATTENDANCE_BASE_URL + `/api/staffAttendance/`;
export const STAFF_CHECKIN_ENDPOINT = `/selfCheckIn`;
export const STAFF_CHECKOUT = ATTENDANCE_BASE_URL + `/api/staffAttendance/`;
export const STAFF_CHECKOUT_ENDPOINT = `/selfCheckOut`;
export const GET_STAFF_ATTENDANCE =
  ATTENDANCE_BASE_URL + `/api/staffAttendance/`;
export const GET_STAFF_ATTENDANCE_ENDPOINT = `/getAttendance`;

//request student attendace
export const STUDENT_ATTENDACE_REQUEST_CREATE =
  ATTENDANCE_BASE_URL + `/api/updateStudentAttendance`;
export const STUDENT_ATTENDACE_REQUEST_GET = ATTENDANCE_BASE_URL + `/api/`;
export const STUDENT_ATTENDACE_REQUEST_GET_ENDPOINT = `/studentAttendanceUpdates`;
export const STUDENT_ATTENDANCE_PUT_REQUEST = ATTENDANCE_BASE_URL + `/api/class/`;
export const STUDENT_ATTENDANCE_PUT_REQUEST_MIDDLE_CONTENT = `/student/`;
export const STUDENT_ATTENDANCE_PUT_REQUEST_END_POINT = `/present/`;


export const EDIT_STUDENT_ATTENDACE_REQUEST = ATTENDANCE_BASE_URL + `/api/`;
export const ATTENDANCE_TAKEN =
  ATTENDANCE_BASE_URL + `/api/attendance-v2/availability`;
export const EDIT_STUDENT_ATTENDACE_REQUEST_ENDPOINT = `/editStudentAttendance`;
export const TIMETABLE_CONFIG_DATA = BASE_URL + `/api/timeTable/`;
export const TIMETABLE_CONFIG_DATA_ENDPOINT = `/getConfigData`;
// http://localhost:8083/v1.0/staff/api/attendance/3/editStudentAttendance
// Library
export const GET_AUTHOR_BOOKS = LIBRARY_BASE_URL + `/api/author/getAuthors`;
export const GET_ALL_CATEGORIES_LIST =
  LIBRARY_BASE_URL + `/api/category/getCategories`;
export const GET_ALL_BOOKS = LIBRARY_BASE_URL + `/api/book/getBooks`;
export const GET_ALL_CATEGORY_BOOKS =
  LIBRARY_BASE_URL + `/api/book/categoryBooks`;
export const GET_BOOK_HISTORY = LIBRARY_BASE_URL + `/api/rental/booksByStatus`;
export const RENT_BOOK_URL = LIBRARY_BASE_URL + `/api/rental/rentBook`;
export const REQUEST_BOOK_URL = LIBRARY_BASE_URL + `/api/bookRequest/`;
export const REQUEST_BOOK_URL_ENDPOINT = `/requestBook`;
export const LATEST_BOOKS_LIST = LIBRARY_BASE_URL + `/api/book/latestBooks`;
export const FAMOUS_BOOKS_LIST = LIBRARY_BASE_URL + `/api/book/famousBooks`;
export const REQUESTED_BOOKS_URL =
  LIBRARY_BASE_URL + `/api/bookRequest/getBookRequests`;
// export const REQUESTED_BOOKS_ENDPOINT = `/getBookRequests`;
export const GET_TOP_AUTHORS_BOOKS_LIST =
  LIBRARY_BASE_URL + `/api/author/topAuthors`;
export const RENT_BOOK_INFO =
  LIBRARY_BASE_URL + `/api/rental/rentalInformationByBook`;
export const MISSING_BOOK_REPORT = LIBRARY_BASE_URL + `/api/rental/`;
export const MISSING_BOOK_REPORT_ENDPOINT = `/reportMissing`;
export const AUTHOR_BOOKS = LIBRARY_BASE_URL + `/api/book/authorBooks`;
export const SUGGESTEDBOOKS = LIBRARY_BASE_URL + `/api/book/create/suggestions`;
export const SUGGESTED_GET_BOOKS =
  LIBRARY_BASE_URL + `/api/book/getSuggestionById`;
export const EDIT_SUGGESTED_BOOK = LIBRARY_BASE_URL + `/api/book/`;
export const EDIT_SUGGESTED_BOOK_ENDPOINT = `/updateSuggestion`;
// tutor
export const ENTROLL_TUTOR = BASE_URL + `/api/tutor/enrollAsTutor`;
//Mettings
export const METTING_LIST = STAFF_BASE_URL + `/api/meeting/`;
export const MEETING_LIST_ENDPOINT = `/getMeetingByStaffId`;
export const REJECT_MEETING = STAFF_BASE_URL + `/api/meeting/`;
export const REJECT_MEETING_ENDPOINT = `/rejectMeeting`;
export const ACCEPT_METTING = STAFF_BASE_URL + `/api/meeting/`;
export const ACCEPT_MEETING_ENDPOINT = `/acceptMeeting`;
export const MEETING_VIEW = STAFF_BASE_URL + `/api/meeting/`;
export const MEETING_VIEW_ENDPOINT = `/getMeetingByStaffId`;

// Institute Info
export const findInstituteInfo =
  INSTITUTE_BASE_URL + `/api/institute/getInstituteDetails`;

export const UPLOAD_PROFILE_PICTURE = STAFF_BASE_URL + `/api/staff/`;
export const UPLOAD_PROFILE_PICTURE_ENDPOINT = `/uploadProfilePic`;
//notification
export const GET_NOTIFICATION = BASE_URL + `/api/notification/`;
export const GET_NOTIFICATION_ENDPOINT = `/getAllNotifications`;
export const NOTIFICATION_UPDATE_READ_STATUS = BASE_URL + `/api/notification/`;
export const NOTIFICATION_UPDATE_READ_STATUS_ENDPOINT = `/updateStatus`;

//transport
export const GET_ALLROUTE = TRANSPORT_BASE_URL + `/api/transport/getAllRoutes`;
export const GET_ALLPICKUP_POINT =
  TRANSPORT_BASE_URL + `/api/transport/getAllPickupPoint`;
export const FIND_ROUTE = TRANSPORT_BASE_URL + `/api/transport/`;
export const FIND_ROUTE_ENDPOINT = `/findRoute`;
export const ENROLL_TRANSPORT_URL =
  TRANSPORT_BASE_URL + `/api/transport/addUserToTransport`;

// Edpedia store

// category
export const GET_ALL_CATEGORIES_PRODUCTS_LIST =
  EDPEDIA_STORE_BASE_URL + `/api/storeCategory/categoryList`;
export const FIND_CATEGORY_PRODUCTS_URL =
  EDPEDIA_STORE_BASE_URL + `/api/storeCategory/`;
export const FIND_CATEGORY_PRODUCTS_URL_ENDPOINT =
  EDPEDIA_STORE_BASE_URL + `/findCategoryById`;

// sub category
export const FIND_SUBCATEGORY =
  EDPEDIA_STORE_BASE_URL + `/api/storeSubCategory/`;
export const FIND_SUBCATEGORY_ENDPOINT = `/findListOfSubCategory`;

//products
export const GET_PRODUCTS_USING_SUB_CATEGORYOID =
  EDPEDIA_STORE_BASE_URL + `/api/product/`;
export const GET_PRODUCTS_USING_SUB_CATEGORYOID_ENDPOINT = `/findProductBySubCategoryId`;
//http://localhost:8088/v1.0/store/api/product/6c69e6da-ae4d-4925-af72-accc5aeda614/findProductBySubCategoryId

// address
export const CREATE_ADDRESS = EDPEDIA_STORE_BASE_URL + `/api/address/`;
export const CREATE_ADDRESS_ENDPOINT = `/createAddress`;
export const FIND_ADDRESS_BY_USER = EDPEDIA_STORE_BASE_URL + `/api/address/`;
export const FIND_ADDRESS_BY_USER_ENDPOINT = `/findByUser`;
export const EDIT_ADDRESS = EDPEDIA_STORE_BASE_URL + `/api/address/`;
export const EDIT_ADDRESS_ENDPOINT = `/edit`;
export const DELETE_ADDRESS = EDPEDIA_STORE_BASE_URL + `/api/address/`;
export const DELETE_ADDRESS_ENDPOINT = `/delete`;

// cart
export const ADD_PRODUCT_TO_CART = EDPEDIA_STORE_BASE_URL + `/cart/`;
export const ADD_PRODUCT_TO_CART_ENDPOINT = `/add`;
export const DELETE_PRODUCT_FROM_CART = EDPEDIA_STORE_BASE_URL + `/cart/`;
export const DELETE_PRODUCT_FROM_CART_ENDPOINT = `/delete/`;
export const CART_LIST = EDPEDIA_STORE_BASE_URL + `/cart/`;

// order
export const USER_ORDER_LIST = EDPEDIA_STORE_BASE_URL + `/api/order/`;
export const USER_ORDER_LIST_ENDPOINT = `/findByUser`;
export const ORDER_FROM_CART = EDPEDIA_STORE_BASE_URL + `/cart/`;
export const ORDER_FROM_CART_ENDPOINT = `/createOrder`;
export const CREATE_ORDER = EDPEDIA_STORE_BASE_URL + `/api/order/create`;

//wishlist
export const WISHLIST_ADD = EDPEDIA_STORE_BASE_URL + `/api/wishlist/add`;
export const GET_WISHLIST_USING_BRANCHCODE =
  EDPEDIA_STORE_BASE_URL + `/api/wishlist/`;
export const GET_WISHLIST_USING_BRANCHCODE_ENDPOINT = `/findByUser`;
export const REMOVE_WISHLIST = EDPEDIA_STORE_BASE_URL + `/api/wishlist/`;
export const REMOVE_WISHLIST_ENDPOINT = `/remove/`;

// students
export const EDIT_STUDENT = BASE_URL + `/api/student/`;
export const EDIT_STUDENT_ENDPOINT = `/edit`;

//questionpaperList
// export const QUESTSIONPAPER_LIST = BASE_URL + `/api/questionPaper/exam/`;
// export const QUESTIONPAPER_LIST = `/all`;

//
export const EXAMLIST_WITH_QUESTIONPAPER_STAFFID =
  STAFF_BASE_URL + `/api/questionPaper/staff/`;
// http://localhost:8084/v1.0/staff/api/questionPaper/staff/2

export const STAFF_IDENTITY_CREATE =
  STAFF_BASE_URL + `/api/staff/staffIdentity/create`;
export const STAFF_IDENTITY_LIST = STAFF_BASE_URL + `/api/staffIdentity/`;
export const STAFF_IDENTITY_LIST_ENDPOINT = `/findStaffDocs`;
export const STAFF_IDENTITY_EDIT = STAFF_BASE_URL + `/api/staffIdentity/`;
export const STAFF_IDENTITY_EDIT_ENDPOINT = `/edit`;

//staff profile upload
export const STAFF_PROFILE_UPLOAD = STAFF_BASE_URL + `/api/staff/`;
export const STAFF_PROFILE_UPLOAD_ENDPOINT = `/uploadProfilePic`;
export const STAFF_FIND = STAFF_BASE_URL + `/api/staff/`;
export const STAFF_FIND_ENDPOINT = `/find`;
//question paper list
export const QUESTIONPAPER_LIST = STAFF_BASE_URL + `/api/questionPaper/staff/`;
export const EDIT_QUESTIONPAPER = STAFF_BASE_URL + `/api/questionPaper/`;
export const EDIT_QUESTIONPAPER_ENDPOINT = `/edit`;
export const DELETE_QUESTIONPAPER = STAFF_BASE_URL + `/api/questionPaper/`;
export const DELETE_QUESTIONPAPER_ENDPOINT = `/delete`;
//reimbursement
export const REIMBURSEMENT_CREATE =
  STAFF_BASE_URL + `/api/reimbursement/create`;
export const REIMBURSEMENT_LIST = STAFF_BASE_URL + `/api/reimbursement/staff/`;
export const REIMBURSEMENT_EDIT = STAFF_BASE_URL + `/api/reimbursement/`;
export const REIMBURSEMENT_EDIT_ENDPOINT = `/edit`;
//homework
export const CREATE_HOMEWORK = `${STAFF_BASE_URL}/api/homeWork/create`;
export const GET_HOMEWORK = `${STAFF_BASE_URL}/api/homeWork/{staffId}/givenByStaff`;
export const DELETE_HOMEWORK = `${STAFF_BASE_URL}/api/homeWork/{staffId}/removeTask`;

//Daily Notes
export const CREATE_DAILY_NOTES = STAFF_BASE_URL + "/api/homeWork/send-daily-notes";
export const UPDATE_DAILY_NOTES = STAFF_BASE_URL + "/api/homeWork/{dailyNotesId}/update-send-daily-notes";
export const GET_DAILY_NOTES = STAFF_BASE_URL + "/api/homeWork/{classId}/get-daily-notes";

// Diary Note
export const CREATE_DIARY_NOTE = STAFF_BASE_URL + "/api/homeWork/send-diary-note";
export const UPDATE_DIARY_NOTE = STAFF_BASE_URL + "/api/homeWork/{diaryNoteId}/update-send-diary-note";
export const GET_DIARY_NOTE = STAFF_BASE_URL + "/api/homeWork/{classId}/get-diary-note";
