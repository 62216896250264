import { Space, Table } from "antd"
import DailyNotesModal from "./DailyNotesModal"
import { EditOutlined, FileOutlined, FilePdfFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { HomeworkService } from "../../Services/HomeworkService";
import FileView from "../../Utils/FileView";
import Cookies from "js-cookie";
import { render } from "@testing-library/react";

const DailyNotes = ({
    dailyNotesModal,
    setDailyNotesModal,
    selectedSection,
    selectedSubject,
}) => {
    const [dailyNotes, setDailyNotes] = useState(null);
    const [record, setRecord] = useState(null);
    const [view, setView] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        getDailyNotesData();
    }, [selectedSection]);

    const getDailyNotesData = () => {
        const params = {
            staffId: Cookies.get("userId"),
        }
        HomeworkService.getDailyNotes(selectedSection?.classId, params)
            .then(response => {
                setDailyNotes(response?.data?.reverse());
            })
            .catch(error => { });
    }

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            align: "center",
        },
        {
            title: "Notes",
              dataIndex: "dailyNotes",
            //   key: "dailyNotes",
            render: (dailyNotes) => dailyNotes ? (<FilePdfFilled onClick={() => handleFileView(dailyNotes)} />) : ("-"),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (description) => description ? description : "-"
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_, record) => (
                <Space>
                    <EditOutlined onClick={() => handleEditDailyNotes(record)} />
                </Space>
            ),
        },
    ];

    const handleFileView = (url) => {
        setFileUrl(url);
        setView(true);
    };

    const handleEditDailyNotes = (record) => {
        console.log('record', record)
        setDailyNotesModal(true);
        setRecord(record);
    }
    const filteredNotes = () => {
        return dailyNotes?.filter(notes => notes?.subjectId === selectedSubject);
    }
    return (
        <>
            <Table
                dataSource={filteredNotes()}
                columns={columns}
                pagination={{ pageSize: 20 }}
            />
            {dailyNotesModal && (
                <DailyNotesModal
                    dailyNotesModal={dailyNotesModal}
                    setDailyNotesModal={setDailyNotesModal}
                    record={record}
                    setRecord={setRecord}
                    selectedSection={selectedSection}
                    selectedSubject={selectedSubject}
                    getDailyNotesData={getDailyNotesData}
                />
            )}
            {view && (
                <FileView
                    view={view}
                    setView={setView}
                    fileData={fileUrl}
                    heading="Daily Notes"
                />
            )}
        </>
    )
}

export default DailyNotes