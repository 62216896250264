import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Select } from "antd";
import { staffServices } from "../../../Services/StaffServices";
import { dynamicErrorMsg } from "../../../Constants/utilitis";
const { Option } = Select;

function SubjectDropdown({ onSubjectChange, selectedSubject, selectedClass, selectedSection = null }) {
  const [subjectData, setSubjectData] = useState(null);
  const [subject, setSubject] = useState(null);

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  const staffId = staffInfo?.staffId;

  const TeachingSubjectsData = () => {
    staffServices
      .subjectList(staffId)
      .then((response) => {
        if (response.status === 200) {
          setSubjectData(response?.data);
        }
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Teaching Subjects")
      });
  };

  useEffect(() => {
    TeachingSubjectsData();
  }, [selectedClass]);

  const filterData = selectedSection != null ? (
    subjectData?.filter(subject => subject?.className === selectedClass && subject?.section === selectedSection?.section)
  ) : (
    subjectData?.filter(
    (item) => item.className === selectedClass
  ));

  const handleIntialClass = () => {
    let initialSubject;
    if (filterData?.some(eachSubject => eachSubject?.subjectId === subject)) {
      initialSubject = selectedSubject;
    }
    else {
      initialSubject = (filterData?.length > 0 ? filterData[0]?.subjectId : undefined);
    }
    setSubject(initialSubject);
    onSubjectChange(initialSubject);
  };

  useEffect(() => {
    handleIntialClass();
  }, [filterData]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const uniqueSubjects = [
    ...new Map(filterData?.map((item) => [item.subjectId, item])).values(),
  ];
  return (
    <Select
      value={subject}
      onChange={(value) => {
        setSubject(value);
        onSubjectChange(value);
      }}
      placeholder="Subject"
      style={{ width: 150 }}
    >
      {uniqueSubjects?.map((option) => (
        <Option key={option.subjectId} value={option.subjectId}>
          {option?.subject ? capitalizeFirstLetter(option.subject) : ""}
        </Option>
      ))}
    </Select>
  );
}

export default SubjectDropdown;
