import { useEffect, useState } from "react";
import ClassDropdown from "../Common/Class/ClassDropdown";
import SectionDropdown from "../Common/Section/SectionDropdown";
import SubjectDropdown from "../Common/Subject/SubjectDropdown";
import { EditOutlined, PlusOutlined, DislikeOutlined, FilePdfFilled, DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Table, notification } from "antd";
import "./homework.scss";
import Cookies from "js-cookie";
import { HomeworkService } from "../../Services/HomeworkService";
import CreateHomework from "./createHomework";
import { dynamicErrorMsg, errorNotification, successNotification } from "../../Constants/utilitis";
import FileView from "../../Utils/FileView";

const Homework = ({
  homeworkModal,
  setHomeworkModal,
  selectedSection,
  selectedSubject,
}) => {
  const [homework, setHomework] = useState([]);
  const [editRow, seteditRow] = useState(null);
  const [view, setView] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const handleEditExpense = (record) => {
    seteditRow(record);
    setHomeworkModal(true);
  };
  const deleteHomework = (record) => {
    Modal.confirm({
      title: "Are you sure to delete homework",
      onOk: () => {
        const params = {
          classId: selectedSection?.classId,
          subjectId: record?.subjectId,
          date: record?.date,
        }
        HomeworkService.deleteHomework(record?.staffId, params)
          .then(response => {
            getHomeworks();
            successNotification(response?.data);
          })
          .catch(error => errorNotification(dynamicErrorMsg(error, "Delete", "homework")));
      }
    })
  }
  const showModal = () => {
    setHomeworkModal(true);
  };
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  const getHomeworks = () => {
    if (!selectedSection) {
      return;
    }
    const params = {
      classId: selectedSection.classId,
      //subjectId: selectedSubject,
    };
    HomeworkService.getHomework(staffId, params)
      .then((response) => {
        setHomework(response?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Homework")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    selectedSection != null && getHomeworks();
  }, [selectedSection]);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        return dateA - dateB;
      },
      defaultSortOrder: "descend",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
    },
    {
      title: "task",
      dataIndex: "task",
      key: "task",
      align: "center",
      sorter: (a, b) => a.task.localeCompare(b.task),
    },
    {
      title: "File",
      dataIndex: "fileUrls",
      render: (fileUrls) => fileUrls.length > 0 ? (<FilePdfFilled onClick={() => handleFileView(fileUrls)} />) : ("-"),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          {/* <EditOutlined onClick={() => handleEditExpense(record)} /> */}
          <DeleteOutlined onClick={() => deleteHomework(record)} />
        </Space>
      ),
    },
  ];
  const handleFileView = (urls) => {
    setFileUrl(urls);
    setView(true);
  };
  const filteredHomework = () => {
    return homework?.filter(work => work?.subjectId === selectedSubject);
  }
  return (
    <>
      {/* <div className="main-container">
        <h1>Homework</h1>
        <div className="fields-container">
          <div>
            <ClassDropdown
              onClassChange={onClassChange}
              selectedClass={selectedClass}
            />
          </div>
          <div>
            <SectionDropdown
              classValue={selectedClass}
              onSectionChange={onSectionChange}
              // selectedSection={selectedSection}
            />
          </div>
          <div>
            <SubjectDropdown
              selectedSubject={selectedSubject}
              onSubjectChange={onSubjectChange}
              selectedClass={selectedClass}
              style={{ width: 200 }}
            />
          </div>
          <Button className="blue-button" onClick={showModal}>
            <PlusOutlined className="assignment-icon" />
            Create Homework
          </Button>
        </div>
      </div> */}

      <Table
        dataSource={filteredHomework()}
        columns={columns}
        showSorterTooltip={false}
        pagination={false}
      />
      {homeworkModal && (
        <CreateHomework
          setHomeworkModal={setHomeworkModal}
          homeworkModal={homeworkModal}
          setRecord={seteditRow}
          record={editRow}
          getHomeworks={getHomeworks}
          activeSection={selectedSection}
          activeSubjectId={selectedSubject}
        />
      )}
      {view && (
        <FileView
          view={view}
          setView={setView}
          fileData={fileUrl}
          heading="Homework"
        />
      )}
    </>
  );
};
export default Homework;
