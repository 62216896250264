import { Button, Radio } from 'antd';
import React, { useState } from 'react'
import { homeworkTabs } from '../../Constants/utilitis';
import ClassDropdown from '../Common/Class/ClassDropdown';
import SectionDropdown from '../Common/Section/SectionDropdown';
import SubjectDropdown from '../Common/Subject/SubjectDropdown';
import Homework from './homework';
import { PlusOutlined } from '@ant-design/icons';
import DailyNotes from './DailyNotes';
import DiaryNote from './DiaryNote';

const HomeWorkMainPage = () => {
    const [activeTab, setActiveTab] = useState(homeworkTabs[0]);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [homeworkModal, setHomeworkModal] = useState(false);
    const [dailyNotesModal, setDailyNotesModal] = useState(false);
    const [diaryNoteModal, setDiaryNoteModal] = useState(false);

    const handleTabChange = (event) => setActiveTab(event.target.value);

    const onClassChange = (value) => {
        setSelectedClass(value);
        setSelectedSubject("");
    };
    const onSubjectChange = (value) => {
        setSelectedSubject(value);
    };
    const onSectionChange = (value) => {
        setSelectedSection(value);
    };

    const RadioGroup = Radio.Group;
    return (
        <main className="homework-main-wrapper">
            <div className="main-container">
                <div>
                    <RadioGroup
                        onChange={handleTabChange}
                        value={activeTab}
                        optionType="button"
                        buttonStyle="solid"
                    >
                        {homeworkTabs?.map(tab => (
                            <Radio.Button key={tab} value={tab}>
                                {tab}
                            </Radio.Button>
                        ))}

                    </RadioGroup>
                </div>
                <div className="fields-container">
                    <div>
                        <ClassDropdown
                            onClassChange={onClassChange}
                            selectedClass={selectedClass}
                        />
                    </div>
                    <div>
                        <SectionDropdown
                            classValue={selectedClass}
                            onSectionChange={onSectionChange}
                        // selectedSection={selectedSection}
                        />
                    </div>
                    {activeTab !== homeworkTabs[2] && <div>
                        <SubjectDropdown
                            selectedSubject={selectedSubject}
                            selectedSection={selectedSection}
                            onSubjectChange={onSubjectChange}
                            selectedClass={selectedClass}
                            style={{ width: 200 }}
                        />
                    </div>}
                    {activeTab === homeworkTabs[0] && (
                        <Button className="blue-button" onClick={() => setHomeworkModal(true)}>
                            <PlusOutlined className="assignment-icon" />
                            Create {homeworkTabs[0]}
                        </Button>
                    )}
                    {activeTab === homeworkTabs[1] && (
                        <Button className="blue-button" onClick={() => setDailyNotesModal(true)}>
                            <PlusOutlined className="assignment-icon" />
                            Create {homeworkTabs[1]}
                        </Button>
                    )}
                    {activeTab === homeworkTabs[2] && (
                        <Button className="blue-button" onClick={() => setDiaryNoteModal(true)}>
                            <PlusOutlined className="assignment-icon" />
                            Create {homeworkTabs[2]}
                        </Button>
                    )}
                </div>
            </div>

            {/* HomeWork Tab */}
            {activeTab === homeworkTabs[0] && (
                <Homework
                    homeworkModal={homeworkModal}
                    setHomeworkModal={setHomeworkModal}
                    selectedSection={selectedSection}
                    selectedSubject={selectedSubject}
                />
            )}
            {/* Daily Notes Tab */}
            {activeTab === homeworkTabs[1] && (
                <DailyNotes
                    dailyNotesModal={dailyNotesModal}
                    setDailyNotesModal={setDailyNotesModal}
                    selectedSection={selectedSection}
                    selectedSubject={selectedSubject}
                />
            )}
            {/* Dairy Notes Tab */}
            {activeTab === homeworkTabs[2] && (
                <DiaryNote
                    diaryNoteModal={diaryNoteModal}
                    setDiaryNoteModal={setDiaryNoteModal}
                    selectedSection={selectedSection}
                />
            )}
        </main>
    )
}

export default HomeWorkMainPage