import React from "react";
import "./EditMetting.scss";
import { Col, Form, Modal, Row, Space, Button, notification } from "antd";
import TextAreaFloatLabel from "../../Common/FloatLabel/TextArea";
import {
  DislikeOutlined,
  LikeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import { meetingServices } from "../../../Services/MeetingServices";
import Cookies from "js-cookie";
import { dynamicErrorMsg } from "../../../Constants/utilitis";
import { MEETING_ACCEPTED_MESSAGE, MEETING_REJECTED_MESSAGE } from "../../../Constants/StringConstants";
const EditMetting = ({
  openModal,
  openApproveModal,
  setOpenModal,
  loadMeetingData,
  setOpenApproveModal,
  meetingData,
  meetingId,
}) => {
  console.log('meetingId', meetingId)
  const [meetingForm] = Form.useForm();
  const handleCancel = () => {
    setOpenModal(false);
    meetingForm.resetFields();
  };
  const cancelApprovalModal = () => {
    setOpenApproveModal(false);
  };

  const handleApprove = () => {
    // const id = staffInfo?.staffId;
    const id = meetingId?.meetingId;
    meetingServices
      .acceptMetting(id)
      .then((response) => {
        cancelApprovalModal();
        loadMeetingData();
        meetingForm.resetFields();
        notification.success({
          message: MEETING_ACCEPTED_MESSAGE,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Create", "Meeting")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
  };

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const onFinish = (values) => {
    // const id = staffInfo?.staffId;\
    const id = meetingId?.meetingId;

    const params = {
      comment: values.comment,
    };

    meetingServices
      .rejectMetting(id, params)
      .then((res) => {
        meetingForm.resetFields();
        handleCancel();
        loadMeetingData();
        notification.success({
          message: MEETING_REJECTED_MESSAGE,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
      })
      .catch((error) => {
        notification.error({
          message: `${error?.data?.data}`,
          icon: (
            <DislikeOutlined
              style={{
                color: "red",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      });
  };

  const onFinishFailed = () => {};

  return (
    <>
      <Modal
        width={500}
        className="resignation-Edit-modal"
        footer={null}
        title="Reject Metting"
        open={openModal}
        onCancel={handleCancel}
        centered
      >
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={meetingForm}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item
                name="comment"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <TextAreaFloatLabel
                  name="decline meassage"
                  label="Comment"
                  rows={8}
                  maxLength={100}
                  autoSize={{ minRows: 4, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="center">
            <Col span={12} size="large" align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button buttons" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="newCancelButton " onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Approve Request"
        open={openApproveModal}
        footer={null}
        onCancel={cancelApprovalModal}
        className="approve-modal"
      >
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          align="center"
          className="modal-buttons-row"
        >
          <Col span={24}>
            <div className="approval-content">
              <p> Are you sure you want Approve this Metting?</p>
            </div>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="center">
          <Col span={12} size="large" align="end">
            <Space direction="vertical" size="large">
              <Button
                className="buttons submit-button"
                htmlType="submit"
                onClick={handleApprove}
              >
                Save
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical" size="large">
              <Button
                className="newCancelButton "
                onClick={cancelApprovalModal}
              >
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EditMetting;
