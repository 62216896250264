import React from "react";
import { Row, Col, Button } from "antd";

const FormButton = ({ onSave, onCancel, saveText, cancelText, disabled, disabledCancel=false }) => {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12} align="end">
        <Button
          type="primary"
          className="buttons blue-button"
          htmlType="submit"
          onClick={onSave}
          disabled = {disabled}
        >
          {saveText}
        </Button>
      </Col>
      <Col span={12}>
        <Button type="danger" disabled={disabledCancel} className="newCancelButton " onClick={onCancel}>
          {cancelText}
        </Button>
      </Col>
    </Row>
  );
};

export default FormButton;
