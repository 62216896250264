import { Button, Col, Form, Modal, Row, Upload } from "antd"
import FormButton from "../Utils/FormButtons";
import { dynamicErrorMsg, errorNotification, homeworkTabs, successNotification } from "../../Constants/utilitis";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import Cookies from "js-cookie";
import { HomeworkService } from "../../Services/HomeworkService";
import TextArea from "antd/es/input/TextArea";

const DailyNotesModal = ({
    dailyNotesModal,
    setDailyNotesModal,
    record,
    setRecord,
    selectedSection,
    selectedSubject,
    getDailyNotesData,
}) => {
    const [dailyNotesForm] = Form.useForm();
    const [file, setFile] = useState([]);

    const handleCancel = () => {
        setDailyNotesModal(false);
        dailyNotesForm.resetFields();
        setRecord(null);
    }
    const onFinish = (values) => {
        console.log('first', values?.description)
        const params = {
            staffId: Cookies.get("userId"),
        }
        const formData = new FormData();
        formData.append("classId", selectedSection?.classId);
        formData.append("description", values?.description)
        file.map((file, i) => {
            formData.append(`notesFile`, file);
        });
        formData.append("subjectId", selectedSubject);

        if (record?.id) {
            HomeworkService.updateDailyNotes(record?.id, formData, params)
                .then(response => {
                    successNotification(response?.data);
                    getDailyNotesData();
                    handleCancel();
                })
                .catch(error => errorNotification(dynamicErrorMsg(error, "Update", "Daily Notes")));
        }
        else {
            HomeworkService.createDailyNotes(formData, params)
                .then(response => {
                    successNotification(response?.data);
                    getDailyNotesData();
                    handleCancel();
                })
                .catch(error => errorNotification(dynamicErrorMsg(error, "Create", "Daily Notes")));
        }
    }

    const handleUpload = ({ fileList }) => {
        const modifiedFiles = [];
        fileList?.forEach(file => modifiedFiles.push(file.originFileObj));
        setFile(modifiedFiles);
    };


    return (
        <Modal
            title={`${record ? "Edit" : "Create"} ${homeworkTabs[1]}`}
            open={dailyNotesModal}
            onCancel={handleCancel}
            footer={null}
        >
            <Form form={dailyNotesForm} onFinish={onFinish} onFinishFailed={() => errorNotification("Please, Upload Notes")}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                        <Form.Item name="dailyNoteFile" 
                        rules={[
                            {
                                required: true,
                                message: "",
                            },
                        ]}>
                            <Upload
                                onChange={handleUpload}
                                type="file"
                                accept=".pdf, .jpg, .jpeg, .png"
                                // maxCount={1}
                                showUploadList={true}
                                beforeUpload={() => false}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    className="blue-button"
                                >
                                    Upload Daily Notes (pdf/image format)
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    </Row>
                    <Row>
                    <Col span={20}>
                        <Form.Item name="description" >
                            <TextArea placeholder="Description" rows={5} cols={40}/>
                        </Form.Item>
                    </Col>
                </Row>

                <FormButton
                    saveText={"Save"}
                    cancelText={"Cancel"}
                    onCancel={handleCancel}
                />
            </Form>
        </Modal>
    )
}

export default DailyNotesModal